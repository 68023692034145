@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2c/src/style/abstract/variables';
@import '../../../../advox-strigo-b2c/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2c/src/style/abstract/button';

:root {
    --account-overlay-border-color: #{$default-secondary-base-color};
    --account-overlay-box-shadow-color: rgba(0, 0, 0, 0.15);
    --my-account-overlay-width: 444px;
    --account-overlay-heading-color: #{$default-primary-base-color};
    --account-overlay-link-color: #{$default-secondary-base-color};
}

.SwipeableTemporaryDrawer-Drawer {
    .CartOverlay {
        @include mobileAndTablet {
            opacity: 1;
        }
    }
}

.MyAccountOverlay,
.SwipeableTemporaryDrawer-Content {
    &:before {
        display: none;
    }
    @include mobileAndTablet {
        padding: 0;
    }
    @include desktop {
        position: absolute;
        top: calc(100% + 10px);
        left: 6px;
        padding: 25px 6px 25px 25px;
        border-radius: 10px;
        background: $white;
        min-width: 80px;
        right: unset;
        left: -320px;
    }
    .MyAccountOverlay-Additional_state_createAccount {
        @include mobileAndTablet {
            display: block;
        }
    }

    &_isVisible {
        @include desktop {
            border: 1px solid var(--account-overlay-border-color);
            box-shadow: 0 0 15px 0 var(--account-overlay-box-shadow-color);
            z-index: 2;
        }
    }

    a {
        @include desktop {
            display: flex;
            align-items: center;

            svg {
                width: 20px;
                height: 20px;
                margin-right: 20px;
            }
        }
    }
    > .Loader {
        @include desktop {
            border-radius: 10px;
        }
    }
    &-Heading {
        font-family: $font-primary;
        font-weight: bold;
        font-size: 20px;
        line-height: 135%;
        letter-spacing: 0.07em;
        color: var(--account-overlay-heading-color);
        margin-bottom: 4px;
    }
    &-Action_state_signIn .Form .Button_likeLink {
        margin-top: 16px;
    }
    &-SignInButton {
        width: auto;
        margin-top: 0;
        svg {
            display: none;
        }
    }
    &-ForgotPassword.Button_likeLink {
        font-family: $font-secondary;
        font-weight: normal;
        font-size: 15px;
        line-height: 150%;
        color: var(--account-overlay-link-color);

        @include mobileAndTablet {
            margin-top: 16px;
        }
    }

    .MyAccountOverlay-Additional_state_forgotPasswordSuccess,
    .Form .Button:not(.Button_likeLink.MyAccountOverlay-ForgotPassword) {
        --button-height: 47px;
        --button-hover-height: 47px;
        --button-border-radius: 7px;
        --button-hover-border-radius: 7px;
        letter-spacing: 0.07em;
        font-weight: bold;
        font-size: 15px;
        line-height: 140%;
        font-family: $font-primary;
        margin-top: 24px;
        margin-bottom: 0;
    }
    &-ConsentRequired {
        color: $grey-dark;
        font-size: 13px;
        margin-left: 7px;
        display: initial;
    }
    &-Consent {
        display: flex;
        align-items: center;
        p {
            margin-bottom: 0;
        }
    }
    .Field-CheckboxLabel {
        max-width: unset;
    }

    .MyAccountOverlay-Action_state_createAccount .Field {
        margin-top: 24px;

        &.Field_type_checkbox {
            .input-control {
                min-width: 21px;
                min-height: 21px;
            }
            .Field-CheckboxLabel {
                max-width: unset;
            }

            .Field-ErrorIcon {
                display: none;
            }

            p {
                margin-bottom: 0;
            }
        }
    }

    .Consents .Field.Field_type_checkbox,
    .Field.Field_type_checkbox.MyAccountOverlay-Checkbox {
        margin: 16px 0 0;
        @include mobileAndTablet {
            margin: 24px 0 0;
        }
        span,
        span p {
            font-weight: normal;
            font-size: 15px;
            line-height: 150%;
            font-family: $font-secondary;
            color: $grey3;
        }
    }
    .Form {
        .Field-InputPasswordBtn {
            right: 16px;
        }
        .Field {
            margin-top: 16px;
            @include mobileAndTablet {
                margin-top: 24px;
            }
        }
        input {
            --input-padding: 16px 24px;
            @include mobileAndTablet {
                max-width: unset;
                width: 100%;
            }
        }
    }
    &-SignUpButton {
        @include tablet {
            width: 100%;
        }
    }
    &-Additional {
        section:not(:first-child) {
            margin-top: 8px;
        }
        h4,
        .Button_likeLink {
            font-family: $font-secondary;
            font-weight: normal;
            font-size: 15px;
            line-height: 150%;
        }
        h4 {
            color: var(--account-overlay-heading-color);
            letter-spacing: unset;
            margin-right: 6px;
        }
        .Button_likeLink {
            color: var(--account-overlay-link-color);
        }

        &.MyAccountOverlay-Additional_state_createAccount,
        &.MyAccountOverlay-Additional_state_signIn,
        &.MyAccountOverlay-Additional_state_forgotPassword {
            @include mobileAndTablet {
                padding-top: 24px;
            }
            > section {
                @include mobileAndTablet {
                    justify-content: center;
                }
            }
            h4 {
                @include mobileAndTablet {
                    margin-bottom: 0;
                }
            }
            .Button.Button_likeLink {
                @include mobileAndTablet {
                    width: auto;
                }
            }
        }
    }

    .Consents {
        .Field-CheckboxLabel span {
            display: block;
        }
    }
}

.MyAccountOverlay {
    &-Action {
        padding: 0 19px 0 0 !important;
        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $default-secondary-base-color;
        }

        &::-webkit-scrollbar-track {
            background-color: $grey5;
        }
    }
}
