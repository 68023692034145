@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2c/src/style/abstract/variables';
@import '../../../../advox-strigo-b2c/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2c/src/style/abstract/button';

.MyAccountAddressTable {
    .Button {
        &_isQuartiary {
            --button-radius: 7px;
            --button-hover-radius: 7px;
            --button-padding: 47px;
            --button-hover-padding: 47px;
            --button-height: 47px;
            --button-hover-height: 47px;
            --button-border: #{$grey5};
            --button-background: #{$grey5};
            --button-color: #{$grey-dark};
            --button-hover-border: #{$grey40};
            --button-hover-background: #{$grey40};
            --button-hover-color: #{$white};
            font-family: $font-primary;
            font-weight: bold;
            font-size: 15px;
            line-height: 140%;
            letter-spacing: 0.07em;
        }
        &_small {
            --button-height: 40px;
            --button-hover-height: 40px;
        }
    }
    tbody {
        th {
            @include desktop {
                width: 13%;
            }
        }
    }
}
